import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { urlReplace } from "../../services/urlReplace";
import { MenuItem } from "../../services/wordpressApi";
import Logo from "../elements/Logo";
import NavDropdownComponent, { useWindowDimensions } from "./NavDropdown";
import { isMobile } from "react-device-detect";
import styles from "./Navigation.module.css";
import { Router, useRouter } from "next/router";
import Button from "../elements/Button";
import LanguageSwitch from "../LanguageSwitch";

import { Img } from "../elements/Img";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";

import leftCircle from "./images/under-header-circle.svg";
import TopInfo from "./TopInfo/TopInfo";
import SiteInfo from "../SiteInfo/SiteInfo";
import cx from "classnames";
export interface INavigationProps {
  menu: MenuItem[];
  translatedSlugs?: Record<string, string>;
}

export default function Navigation({
  menu,
  translatedSlugs,
}: INavigationProps) {
  const { language } = useContext(LanguageContext);
  const router = useRouter();
  const [expanded, setExpanded] = useState(false);
  const { height, width } = useWindowDimensions();

  const isMobileOrSmall = isMobile || width < 992;

  useEffect(() => {
    const closeNavOnPageChange = () => {
      if (isMobileOrSmall) {
        setExpanded(false);
      }
    };

    Router.events.on("routeChangeStart", closeNavOnPageChange);
    return () => {
      Router.events.off("routeChangeStart", closeNavOnPageChange);
    };
  }, []);

  return (
    <>
      <div className={cx(styles.root, { [styles.open]: expanded })}>
        <TopInfo />
        <Navbar expand="lg" variant="light" sticky="top" expanded={expanded}>
          <Container className={styles.container}>
            <Link href={language === "en" ? "/en" : "/"}>
              <a>
                <Navbar.Brand>
                  <Logo className={styles.logo} />
                </Navbar.Brand>
              </a>
            </Link>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Nav>
                {menu.map((element) => {
                  if (element.children.length > 0) {
                    return (
                      <NavDropdownComponent
                        key={`main-menu-dropdown-component-${element.title}`}
                        element={element}
                      />
                    );
                  } else {
                    return (
                      <Link
                        href={urlReplace(element.url)}
                        key={`main-menu-link-item-${element.title}`}
                      >
                        <a>
                          <div
                            key={`main-menu-item-${element.title}`}
                            className="navbar-1lvl-item navbar-1lvl-link"
                            dangerouslySetInnerHTML={{ __html: element.title }}
                          />
                        </a>
                      </Link>
                    );
                  }
                })}
                <div
                  className={`${styles.buttonWrapper} ${styles.desktopHidden}`}
                >
                  <Link
                    href="#purchase_now"
                    passHref
                    className={styles.buttonWrapper}
                  >
                    <a>
                      <Button variant="primary">
                        {translations[language].purchaseNow}
                      </Button>
                    </a>
                  </Link>
                  <Link
                    href="#verify_level"
                    passHref
                    className={styles.buttonWrapper}
                  >
                    <a>
                      <Button variant={"primary"}>
                        {translations[language].verifyLevel}
                      </Button>
                    </a>
                  </Link>
                </div>
              </Nav>
              <div className={styles.mobileLanguageSwitchWrapper}>
                <p className={styles.mobileLanguageLabel}>
                  {translations[language].websiteLanguage}
                </p>
                <LanguageSwitch translatedSlugs={translatedSlugs} />
              </div>
            </Navbar.Collapse>
            <div
              className={`${styles.buttonWrapper} ${styles.mobileHidden} ${styles.hiddenSmallDesktop}`}
            >
              <Link
                href="#purchase_now"
                passHref
                className={styles.buttonWrapper}
              >
                <a>
                  <Button variant="primary">
                    {translations[language].purchaseNow}
                  </Button>
                </a>
              </Link>
              <Link
                href="#verify_level"
                passHref
                className={styles.buttonWrapper}
              >
                <a>
                  <Button variant="secondary">
                    {translations[language].verifyLevel}
                  </Button>
                </a>
              </Link>
            </div>
            {/* <div className={styles.searchWrapper}>
              <Img src={searchIcon} />
            </div> */}
            <div className={styles.desktopLanguageSwitchWrapper}>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setExpanded(!expanded)}
              />
              <LanguageSwitch translatedSlugs={translatedSlugs} />
            </div>
          </Container>
        </Navbar>
        <Img className={styles.leftCircle} src={leftCircle} />
      </div>
      {/* <SiteInfo /> */}
    </>
  );
}
